import React from "react";
import linkedin from "../../assets/team/linkedin.svg";
import twitter from "../../assets/team/twitter.svg";
import instagram from "../../assets/team/instagram.svg";
import mail from "../../assets/team/mail.svg";
import teamOmid from "../../assets/team-omid.png";
import teamPatric from "../../assets/team-patric.png";
import teamAnas from "../../assets/team-anas.png";

interface ITeamData {
  name: string,
  position: string,
  about: string,
  image: string,
  links: {
    instagram?: string,
    twitter?: string,
    linkedin?: string,
    mail?: string
  }
}

const MeetOurTeam: React.FC = () => {
  const teamData: ITeamData[] = [
    {
      name: "PATRIC J.L",
      position: "CEO",
      about: "Head of visions and design. Crypto enthuast and since more then 6 years in the emerald business.",
      image: teamPatric,
      links: {
        instagram: "https://www.instagram.com/Panjshir.emerald",
        twitter: "https://twitter.com/Theonethingman",
        mail: "mailto: Ceo@cryptor.dev"
      }
    },
    {
      name: "OMID B",
      position: "COO",
      about: "Head of emerald mining. Graduated by business of administraton University and completed sucessfully the bachelor.",
      image: teamOmid,
      links: {
        instagram: "https://www.instagram.com/panjshir.emeralds",
        twitter: "https://twitter.com/omidbashiri",
        linkedin: "https://www.linkedin.com/in/omidbashiri",
        mail: "mailto: Emeraldspp@gmx.net"
      }
    },
    {
      name: "M. ANAS AFZAL",
      position: "CTO",
      about: "Head of tech and development. Full Stack Blockchain Developer",
      image: teamAnas,
      links: {
        linkedin: "https://www.linkedin.com/in/muhammadanasafzal/",
        mail: "mailto: anas_afzal@cryptor.dev"
      }
    }
  ];

  return (
    <div
      className="w-full text-white text-center sm:mt-28 space-y-5 my-10"
      id="team"
    >
      {" "}
      <h1 className="text-4xl md:text-5xl font-bold font-heading">MEET OUR TEAM</h1>
      <div className="flex flex-col md:flex-row space-y-16 md:space-y-0">
        {teamData.map((item) => (
          <div className="md:w-1/2 mx-10" key={item.name}>
            <div className="relative">
              <img src={item.image} alt="" className=" h-64 mx-auto"/>
              <div className="text-white">
                <h5
                  className="text-right text-2xl pr-5"
                  style={{ color: "#1CD465" }}
                >
                  {item.name}
                </h5>
                <p className="text-right pr-5 text-sm">{item.position}</p>
                <div>
                  <div style={{ backgroundColor: "#05040E", padding: "5px 0px" }}>
                    <p className="text-center text-xs p-0">
                      {item.about}
                    </p>
                    <div className="pb-2 flex justify-center">
                      {item.links.instagram && (
                        <span className="inline-block p-3">
                        <a href={item.links.instagram}><img src={instagram} style={{ width: "10px" }} alt=""/></a>
                        </span>
                      )}
                      {item.links.twitter && (
                        <span className="inline-block p-3">
                        <a href={item.links.twitter}><img src={twitter} style={{ width: "10px" }} alt=""/></a>
                        </span>
                      )}
                      {item.links.linkedin && (
                        <span className="inline-block p-3">
                        <a href={item.links.linkedin}><img src={linkedin} style={{ width: "10px" }} alt=""/></a>
                        </span>
                      )}
                      {item.links.mail && (
                        <span className="inline-block p-3">
                        <a href={item.links.mail}><img src={mail} style={{ width: "10px" }} alt=""/></a>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      </div>
    </div>
  );
};

export default MeetOurTeam;
