import styled from "styled-components";
import { md } from "../../utilities";

export const StyledContact = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    margin: 0 30px 70px 30px;

    @media ${md} {
        margin: 0 0 70px 0;
    }
`;

export const StyledSubscribeWrappar = styled.div`
    display: flex;
    flex-direction: column;

    @media ${md} {
        flex-direction: row;
        padding: 0 40px 0 40px;
    }
`;

export const StyledSubscribeSection1 = styled.div`
    color: white;
    max-width: 300px;
    margin: 0 auto 0 auto;

    @media ${md} {
        margin: 0;
        width: 250px;
    }
`;

export const StyledSubscribeSection2 = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 30px 0 30px;

    @media ${md} {
        flex-direction: row;
        column-gap: 20px;
        margin: auto 0 auto auto;
    }
`;

export const StyledSubscribeEmailInput = styled.input`
    background-color: transparent;
    border-bottom: 1px solid #707070;
    width: 100%;
    max-width: 350px;
    padding: 10px 0 10px 0;
    font-size: 14px;
    color: #707070;
    margin: 20px auto 0 auto;
    text-align: center;
    border-radius: 0;

    @media ${md} {
        text-align: left;
        margin: 0;
        width: 400px;
        border-color: white;
    }
`;

export const StyledSubscribeButton = styled.button`
    border-bottom: 1px solid white;
    color: white;
    padding: 10px 0 10px 0;
    font-size: 14px;
    width: max-content;
    margin: 20px auto 0 auto;

    @media ${md} {
        margin: 0;
    }
`;

export const StyledStayConnectedHeading = styled.h1`
    margin-bottom: 20px;
    text-align: center;
    font-size: 34px;
    font-family: 'Bebas Neue';

    @media ${md} {
        text-align: left;
        font-size: 22px;
        font-family: 'Poppins';
    }
`;

export const StyledSubscribeText = styled.p`
    font-size: 14px;
    font-weight: 300;
    text-align: center;

    @media ${md} {
        text-align: left;
    }
`;

export const StyledContactFormMessage = styled.h1`
    font-size: 34px;
    margin: 50px auto 0 auto;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Bebas Neue';

    @media ${md} {
        font-size: 30px;
        font-family: 'Poppins';
    }
`;

export const StyledContactForm = styled.div`
    background: rgba(255, 255, 255, 0.12);
    width: 100%;
    max-width: 500px;
    border-radius: 50px;
    margin: 50px auto 0 auto;

    @media ${md} {
        background-color: rgba(15, 14, 14, 1);
        border-radius: 30px;
    }
`;

export const StyledContactFormContent = styled.div`
    border-radius: 50px;
    background: linear-gradient(47.28deg, rgba(28, 212, 101, 0) 43.46%, rgba(28, 212, 101, 0) 69.81%, rgba(28, 212, 101, 0.12) 100%);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    padding: 50px;

    @media ${md} {
        border-radius: 30px;
        background: linear-gradient(47.28deg, rgba(28, 212, 101, 0) 43.46%, rgba(28, 212, 101, 0) 69.81%, rgba(28, 212, 101, 0.12) 100%);
    }
`;

export const StyledNameInput = styled.input`
    padding-bottom: 10px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #707070;
    background-color: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
`;

export const StyledEmailInput = styled(StyledNameInput)`

`;

export const StyledPhoneInput = styled(StyledNameInput)``;

export const StyledMessageInput = styled.textarea`
    font-size: 14px;
    color: #707070;
    background-color: transparent;
    border-bottom: 1px solid white;
    margin-bottom: 50px;
    height: 100px;
    border-radius: 0;
`;

export const StyledSendMessageButton = styled.button`
    border-bottom: 1px solid white;
    color: white;
    padding: 0 0 10px 0;
    font-size: 14px;
    width: max-content;
    margin: 0 0 0 auto;
`;

export const StyledFormNotWorkingWarning = styled.h3`
    font-size: 20px;
    margin: 50px auto 0 auto;
    color: #FFCC00;
    font-weight: 700;
    font-family: 'Poppins';
    text-align: center;

    @media ${md} {
        font-size: 20px;
        
    }
`;

export const StyledEmailLink = styled.a`
    text-decoration: underline;
`;