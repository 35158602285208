import gsap from "gsap/all";
import React from "react";
import intro3d from "../../assets/3d-intro.svg";
import {
  StyledEmbeded,
  StyledIntroQuoteHeading,
  StyledIntroSection,
  StyledIntroText,
  StyledQuoteText,
  StyledViciSpan,
  StyledWithCryptorLabel,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { md } from "../../utilities";
import litePaper from "../../assets/litepaper.pdf";

const Intro: React.FC = () => {
  const isDesktop = useMediaQuery(md);
  return (
    <StyledIntroSection id="intro">
      <div className="relative">
        <img
          src={intro3d}
          alt=""
          className="absolute h-72 2xl:h-auto left-full bottom-0 2xl:top-0 -ml-64 z-0 md:block  xs:hidden "
        />
        <StyledIntroQuoteHeading>CONQUER</StyledIntroQuoteHeading>
        <StyledWithCryptorLabel>WITH CRYPTOR</StyledWithCryptorLabel>
        <StyledQuoteText>
          CAESAR'S QUOTE: “VENI VIDI <StyledViciSpan>$VICI</StyledViciSpan>”
        </StyledQuoteText>
        <StyledIntroText>
          Cryptor brings innovation and functionality together, creating the
          first-of-its-kind interactive crypto database with tools that disrupt
          the world of crypto as you know it.
        </StyledIntroText>
        <div className="max-w-max mx-auto sm:mx-0 space-x-4 mt-6">
          <a
            className="cursor-pointer border-b-2 text-white py-3 font-light shadow-2xl white-paper-button white-paper"
            href={litePaper}
            target="_blank"
            onMouseEnter={() => {
              gsap.to(".white-paper", {
                color: "#22C55E",
                duration: 0.2,
                borderColor: "#22C55E",
              });
            }}
            onMouseLeave={() => {
              gsap.to(".white-paper", {
                color: "#ffffff",
                duration: 0.2,
                borderColor: "#ffffff",
              });
            }}
            rel="noreferrer"
          >
            Lite paper
          </a>
          <a
            style={{ marginLeft: "30px" }}
            href="https://app.uniswap.org/#/swap?inputCurrency=0x6e01A56F0Fd8e08B84297235c5847dCC469C96C9"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer border-b-2 text-white py-3 font-light shadow-2xl white-paper-button cryptor-swap-button"
            onMouseEnter={() => {
              gsap.to(".cryptor-swap-button", {
                color: "#22C55E",
                duration: 0.2,
                borderColor: "#22C55E",
              });
            }}
            onMouseLeave={() => {
              gsap.to(".cryptor-swap-button", {
                color: "#ffffff",
                duration: 0.2,
                borderColor: "#ffffff",
              });
            }}
          >
            Cryptor Swap
          </a>
        </div>
      </div>
      <div className="flex flex-col text-center space-y-8 text-white">
        <StyledEmbeded
          url="https://www.youtube.com/watch?v=qf6l_XRLyqs&ab_channel=Cryptor"
          width={isDesktop ? 640 : 350}
          height={isDesktop ? 360 : 200}
        />
        <h1 className=" text-4xl md:text-5xl font-bold font-heading">
          COMING SOON
        </h1>
        <p className="font-light text-sm max-w-lg mx-auto opacity-50">
          Be one of the first ones to get our industry-leading application.
          Become a Cryptor (VICI) holder, and enjoy a wide range of benefits
          free of charge.
        </p>
        <a
          href="https://T.me/cryptortalk"
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer border-b-2 text-white py-3 font-light shadow-2xl white-paper-button early-access-button w-max m-auto"
          onMouseEnter={() => {
            gsap.to(".early-access-button", {
              color: "#22C55E",
              duration: 0.2,
              borderColor: "#22C55E",
            });
          }}
          onMouseLeave={() => {
            gsap.to(".early-access-button", {
              color: "#ffffff",
              duration: 0.2,
              borderColor: "#ffffff",
            });
          }}
        >
          Get Early access
        </a>
      </div>
    </StyledIntroSection>
  );
};

export default Intro;
