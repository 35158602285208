import React from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { lg, md } from "../../../utilities";
import { StyledCountdownCircleTimer, StyledCountDownDimension, StyledCountDownTime, StyledCountDownTimerContainer, StyledTimerContent } from "./style";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

export const CountDownTimer = () => {
    const isMd = useMediaQuery(md);
    const isLg = useMediaQuery(lg);

    const timerProps = {
    isPlaying: true,
    size: isLg ? 120 : isMd ? 100 : 80,
    strokeWidth: 3
    };

    const renderTime = (dimension: string, time: number) => {
    return (
        <StyledTimerContent>
        <StyledCountDownTime className="time">{time}</StyledCountDownTime>
        <StyledCountDownDimension>{dimension}</StyledCountDownDimension>
        </StyledTimerContent>
    );
    };

    const getTimeSeconds = (time: number) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time: number) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time: number) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time: number) => (time / daySeconds) | 0;

    const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = 1647730799; // use UNIX timestamp in seconds

    const remainingTime = endTime - startTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    return (
    <StyledCountDownTimerContainer >
        <StyledCountdownCircleTimer
        {...timerProps}
        colors="#24D269"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
        >
        {({ elapsedTime, color }) => (renderTime("days", getTimeDays(daysDuration - elapsedTime)))}
        </StyledCountdownCircleTimer>
        <StyledCountdownCircleTimer
        {...timerProps}
        colors="#24D269"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
        })}
        >
        {({ elapsedTime, color }) => (renderTime("hours", getTimeHours(daySeconds - elapsedTime)))}
        </StyledCountdownCircleTimer>
        <StyledCountdownCircleTimer
        {...timerProps}
        colors="#24D269"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
        })}
        >
        {({ elapsedTime, color }) => (renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime)))}
        </StyledCountdownCircleTimer>
        <StyledCountdownCircleTimer
        {...timerProps}
        colors="#24D269"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > 0
        })}
        >
        {({ elapsedTime, color }) => (renderTime("seconds", getTimeSeconds(elapsedTime)))}
        </StyledCountdownCircleTimer>
    </StyledCountDownTimerContainer>
    );
}
