import styled from "styled-components";
import { md, sm } from "../../utilities";

export const StyledTokenomics = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-top: 50px;
  position: relative;
  z-index: 0;
`;

export const StyledTokenNameLabel = styled.p`
  font-size: 14px;
  opacity: 0.5;
  margin: 0 auto 0 auto;
`;

export const StyledTokenName = styled.h2`
  font-size: 36px;
  margin: 0 auto 0 auto;
  font-family: "Bebas Neue";

  @media ${md} {
    font-size: 48px;
  }
`;

export const StyledTokenSaleHeading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin: 70px auto 0 auto;
  font-family: "Bebas Neue";

  @media ${sm} {
    font-size: 48px;
    margin: 80px 0 0 0;
  }
`;

export const StyledTokenSaleTimerWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;

  @media ${sm} {
    flex-direction: row;
  }
`;

export const StyledTokenSaleTimerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media ${sm} {
    padding: 0;
    width: 65%;
  }
`;

export const StyledTokenSaleTimer = styled.img`
  margin-top: 20px;
`;

export const StyledTokenSaleDescription = styled.p`
  margin-top: 40px;
  color: white;
  opacity: 0.5;
  font-size: 14px;
  text-align: center;

  @media ${sm} {
    text-align: left;
  }
`;

export const StyledTokenSaleActions = styled.div`
  margin-top: 40px;
  width: max-content;
  margin: 40px auto 0 auto;

  @media ${sm} {
    margin: 40px 0 0 0;
  }
`;

export const StyledBuyTokenButton = styled.a`
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  opacity: 0.7;
  font-size: 14px;
`;

export const StyledFaqButton = styled(StyledBuyTokenButton)`
  margin-left: 20px;
`;

export const StyledTokenSaleCoin = styled.img`
  @media ${sm} {
    width: 45%;
  }
`;

export const StyledTokenStatsWrappar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  margin: 50px auto 80px auto;
  width: 100%;
  max-width: 400px;

  @media ${md} {
    max-width: 100%;
    display: flex;
    margin: 80px 0 80px 0;
  }
`;

export const StyledTokenStat = styled.div`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 10px;
  text-align: center;
  width: 100%;
  place-self: baseline;

  @media ${md} {
    flex-direction: column;
    place-self: auto;
  }
`;

export const StyledStatLabel = styled.p`
  font-size: 15px;
  opacity: 0.5;
`;

export const StyledStatValue = styled.h3`
  font-size: 30px;
  font-weight: 700;
`;

export const StyledWaves = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 80px;
  z-index: -1;

  @media ${md} {
    margin-bottom: 0;
  }
`;
