import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sm } from "../../../utilities";

export const StyledRoadMapSlider = styled(Slider)`

`;

export const StyledRoadMapSliderContent = styled.div``;

export const StyledRoadMapHeading = styled.h3<{active: boolean}>`
    font-size: 20px;
    font-weight: 500;
    color: ${({active}) => active ? "#49FF95" : "white"};
    opacity: ${({active}) => active ? 1 : 0.5};
    min-height: 50px;

    @media ${sm} {
        min-height: 80px;
    }
`;

export const StyledRoadMapDescription = styled.p<{active: boolean}>`
    font-size: 14px;
    font-weight: 300;
    color: white;
    opacity: ${({active}) => active ? 1 : 0.5};
    text-align: center;
`;