import {
  StyledAnswer,
  StyledCloseButton,
  StyledCloseImg,
  StyledFaqHeading,
  StyledHeader,
  StyledQuestion,
} from "./styles";
import Modal, { Styles } from "react-modal";
import closeButton from "../../assets/close-button.svg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { md } from "../../utilities";
import React from "react";

interface IProps {
  open: boolean;
  showFaq: () => void;
}

export const FaqModal: React.FC<IProps> = ({ open, showFaq }: IProps) => {
  const faq = [
    {
      question: "Is Cryptor safe?",
      answer:
        "Information security and reliability are two of our main value pillars, hence we are fully transparent and verifiable by our users at any point in time.",
    },
    {
      question: "What can I use Cryptor for?",
      answer:
        "We offer numerous industry leading DEX tools with a completely new face.  ",
    },
    {
      question: "Does Cryptor guarantee I will make money?",
      answer:
        "Despite the very high chance of you turning profitable when engaging with legitimate digital research tools, Cryptor does not guarantee any profit associated with your investments, and is not liable for any losses that might occur on or off the platform.",
    },
    {
      question: "When can I buy Cryptor (VICI)?",
      answer:
        "Don’t worry, we are just as excited as you are: the token distribution is set to begin early 2022, so stick around, and be one of the first holders. Official date of the fair launch is coming soon.",
    },
    {
      question: "Where can I buy Cryptor (VICI)?",
      answer: "Soon on Uniswap, or on our own dexchange, SWAPTOR. ",
    },
    {
      question: "Why should I use Cryptor?",
      answer:
        "This might be the simplest question to answer: Cryptor is the ultimate crypto database that will help you maximize your profits by locating the most optimal, newly emerging cryptocurrencies available worldwide.",
    },
  ];

  const isDesktop = useMediaQuery(md);

  const customModalStyles: Styles = {
    content: {
      backgroundColor: "#03161C",
      marginLeft: isDesktop ? "20%" : "",
      marginRight: isDesktop ? "20%" : "",
      marginTop: "5%",
      marginBottom: "5%",
      //height: 'max-content',
      borderRadius: "20px",
      borderColor: "transparent",
      maxHeight: "initial",
    },
    overlay: {
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      isOpen={open}
      style={customModalStyles}
      shouldCloseOnOverlayClick={true}
    >
      <StyledHeader>
        <StyledFaqHeading>frequently asked questions (FAQ)</StyledFaqHeading>
        <StyledCloseButton onClick={showFaq}>
          <StyledCloseImg src={closeButton} />
        </StyledCloseButton>
      </StyledHeader>
      {faq.map((item, i) => (
        <React.Fragment key={i}>
          <StyledQuestion>{item.question}</StyledQuestion>
          <StyledAnswer>{item.answer}</StyledAnswer>
        </React.Fragment>
      ))}
    </Modal>
  );
};
