import styled from "styled-components";
import { md } from "../../../utilities";

export const StyledSvgWrapper = styled.svg`
    margin: 40px auto 65px auto;
    width: 100%;
    padding: 0 15px 0 15px;

    @media ${md} {
        width: 66%;
        margin: 40px auto 80px auto;
        padding: 0;
    }
`;