import styled from "styled-components";
import { md } from "../../utilities";

export const StyledWhitePaper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    
    @media ${md} {
        flex-direction: row;
        margin-top: 60px;
    }
`;

export const StyledWhitePaperImg = styled.img`
    width: 100%;
    max-width: 400px;
    margin: 0 auto 0 auto;

    @media ${md} {
        max-width: 50%;
        margin: 0;
    }
`;

export const StyledWhitePaperContent = styled.div`
    text-align: center;

    @media ${md} {
        margin: auto 0 auto 0;
        text-align: left;
        width: 400px;
    }
`;

export const StyledRealLabel = styled.h1`
    font-weight: 700;
    font-size: 55px;
    font-family: 'Bebas Neue';
`;

export const StyledStartupLabel = styled.h1`
    font-weight: 700;
    font-size: 65px;
    font-family: 'Bebas Neue';
`;

export const StyledWhitePaperText = styled.p`
    font-size: 14px;
    opacity: 0.5;
    max-width: 400px;
    margin: 20px auto 20px auto;

    @media ${md} {
        margin: 20px 0 30px 0;
        max-width: 100%;
    }
`;