import React from "react";
import whitePaper from "../../assets/whitepaper.png";
import { StyledWhitePaper, StyledWhitePaperImg, StyledWhitePaperContent, StyledRealLabel, StyledStartupLabel, StyledWhitePaperText } from "./styles";
import litePaper from "../../assets/litepaper.pdf";

const WhitePaper: React.FC = () => {
    return (
        <StyledWhitePaper>
            <StyledWhitePaperImg src={whitePaper} alt="" />
            <StyledWhitePaperContent>
                <StyledRealLabel>THE REAL</StyledRealLabel>
                <StyledStartupLabel className="text-4xl font-bold text-green-400">STARTUP</StyledStartupLabel>
                <StyledWhitePaperText>
                    A young and motivated Team working 24/7 to make crypto great again.
                    Download out whitepaper to get more information about the company CRYPTOR
                </StyledWhitePaperText>
                <a href={litePaper} className="border-b pb-2" target="_blank" rel="noreferrer">Lite paper</a>
            </StyledWhitePaperContent>
        </StyledWhitePaper>
    );
};

export default WhitePaper;