import styled from "styled-components";
import { md } from "../../utilities";

export const StyledQuoteWrappar = styled.div`
    display: flex;
    margin: 40px auto 0 auto;
    padding: 0 30px 0 30px;
    
    @media ${md} {
        padding: 0;
        margin: 60px auto 0 auto;
    }
`;

export const StyledOpenQuotationMark = styled.img`
    height: 20px;
`;

export const StyledCloseQuotationMark = styled.img`
    height: 20px;
`;

export const StyledQuoteContent = styled.div`
    max-width: 500px;
    margin: 10px 5px 0 5px;
`;

export const StyledQuote = styled.p`
    text-align: center;
    font-size: 14px;
    color: white;
    opacity: 0.5;
`;

export const StyledQuotePersonName  = styled.h4`
    margin-top: 5px;
    text-align: center;
    color: #25D46A;
    font-size: 17px;

    @media ${md} {
        margin-top: 10px;
    }
`;

export const StyledQuotePersonPosition = styled.h4`
    margin-top: 30px;
    font-size: 16px;
    opacity: 0.5;
    text-align: center;
`;