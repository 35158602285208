import React from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { md } from "../../../utilities";
import {
  StyledRoadMapDescription,
  StyledRoadMapHeading,
  StyledRoadMapSlider,
  StyledRoadMapSliderContent,
} from "./style";

interface IProps {
  handleSlideNumber: (id: number) => void;
  activeSlide: number;
  slides: Array<{ id: number; heading: String; text: String }>;
}

export const RoadMapSlider: React.FC<IProps> = (props: IProps) => {
  const match = useMediaQuery(md);

  const settings = {
    className: "center",
    centerMode: match ? true : false,
    infinite: true,
    arrows: false,
    centerPadding: "60px",
    slidesToShow: match ? 3 : 1,
    speed: 2000,
    autoplay: true,
    cssEase: "linear",
    beforeChange: (next: number) => props.handleSlideNumber(-1),
    afterChange: (current: number) => props.handleSlideNumber(current),
  };

  return (
    <div>
      <p
        className={
          props.activeSlide !== -1
            ? "text-green-500 text-center text-2xl font-bold"
            : "text-gray-300 text-center text-2xl font-bold"
        }
      >
        __
      </p>
      <StyledRoadMapSlider {...settings}>
        {props.slides.map((data, i) => {
          return (
            <StyledRoadMapSliderContent key={i} className="p-5">
              <StyledRoadMapHeading
                active={props.activeSlide === data.id ? true : false}
              >
                {data.heading}
              </StyledRoadMapHeading>
              <StyledRoadMapDescription
                active={props.activeSlide === data.id ? true : false}
              >
                {data.text}
              </StyledRoadMapDescription>
            </StyledRoadMapSliderContent>
          );
        })}
      </StyledRoadMapSlider>
    </div>
  );
};
