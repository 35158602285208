import React, { useState } from "react";
import Intro from "../components/Intro/Intro";
import Header from "../components/header/Header";
import MeetOurTeam from "../components/MeetOurTeam/MeetOurTeam";
import RoadMap from "../components/RoadMap/RoadMap";
import Tokenomics from "../components/Tokenomics/Tokenomics";
import WhatWeDo from "../components/WhatWeDo/WhatWeDo";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import cryptorBg from "../assets/cryptor-bg.png";
import { FaqModal } from "../components/FaqModal";
import { DisclaimerModal } from "../components/DisclaimerModal";

const Landing: React.FC = () => {
  const [openFaq, isOpenFaq] = useState<boolean>(false);
  const [openDisclaimer, isOpenDisclaimer] = useState<boolean>(false);
  const showFAQ = () => {
    isOpenFaq((open) => !open);
  };

  const showDisclaimer = () => {
    isOpenDisclaimer((open) => !open);
  };

  return (
    <div className="relative">
      <img
        src={cryptorBg}
        alt=""
        className="absolute h-full object-cover object-center w-full"
      />
      <div className="lg:w-4/5 2xl:w-lg w-full h-full sm:mx-auto relative">
        <FaqModal open={openFaq} showFaq={showFAQ}></FaqModal>
        <DisclaimerModal open={openDisclaimer} showDisclaimer={showDisclaimer}></DisclaimerModal>
        <Header />
        <Intro />
        <WhatWeDo />
        <MeetOurTeam />
        <RoadMap />
        <Tokenomics showFAQ={showFAQ}/>
        <Contact />
        <Footer showDisclaimer={showDisclaimer} showFaq={showFAQ}/>
      </div>
    </div>
  );
};

export default Landing;
