import React from "react";
import { Settings } from "react-slick";
import { StyledDescription, StyledHeading, StyledPartition, StyledTokenAllocationSlide, StyledTokenAllocationSlideContent, StyledTokenAllocationSlider, StyledTokenAllocationSlideWrapper } from "./styles";
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { md } from "../../../utilities";

interface ISlideDataProps {
    id: number;
    heading: string;
    headingColor: string;
    description: string;
}

interface IProps {
    current: number;
    changeIndex: (currentIndex: number) => void;
}

export const TokenAllocationSlider: React.FC<IProps> = ({current, changeIndex}: IProps) => {

    const slidesData: ISlideDataProps[] = [
        {
            id: 0,
            heading: "15% Founder & Co",
            headingColor: "#2DFF85",
            description: "To ensure Cryptor is best represented across all platforms, our founders will receive part of our treasury as incentive for their efforts."
        },
        {
            id: 1,
            heading: "7% Bounty & Rewards",
            headingColor: "#1DA854",
            description: "You will have the chance to get rewarded by participating in our Bug Hunter contests and improvement contests."
        },
        {
            id: 2,
            heading: "3% Marketing",
            headingColor: "#167E3F",
            description: "Even your grandmother will know about us!"
        },
        {
            id: 3,
            heading: "4% Development & NFT Marketplace etc",
            headingColor: "#36D04F",
            description: "Success can only last if we keep the system up to date."
        },
        {
            id: 4,
            heading: "2% Legal & Compliance",
            headingColor: "#0CB552",
            description: "We’ll make sure to be fully compliant to keep our users secure."
        },
        {
            id: 5,
            heading: "9% company expanding",
            headingColor: "#079E44",
            description: "Conquering the world requires money."
        },
        {
            id: 6,
            heading: "17% CEX exchanges",
            headingColor: "#23E770",
            description: "17% of Cryptor (VICI) will be allocated to cexchanges."
        },
        {
            id: 7,
            heading: "43% Dex Exchange",
            headingColor: "#007E35",
            description: "We’ll delegate 43% of all Cryptor (VICI) to the Uniswap dexchange."
        },
    ]

    const isDesktop = useMediaQuery(md);
    const settings: Settings = {
        infinite: true,
        autoplay: true,
        centerMode: true,
        slidesToShow: isDesktop ? 3 : 1,
        cssEase: "linear",
        arrows: false,
        afterChange: (current) => {changeIndex(current)}
    };

    return (
        <StyledTokenAllocationSlider {...settings}>
            { 
                slidesData.map((item, index) => (
                    <StyledTokenAllocationSlideWrapper key={item.id}>
                        <StyledTokenAllocationSlide>
                            <StyledPartition></StyledPartition>
                            <StyledTokenAllocationSlideContent>
                                <StyledHeading color={item.headingColor}>{item.heading}</StyledHeading>
                                <StyledDescription active={index === current ? true : false}>{item.description}</StyledDescription>
                            </StyledTokenAllocationSlideContent>
                        </StyledTokenAllocationSlide>
                    </StyledTokenAllocationSlideWrapper>
                ))
            }
        </StyledTokenAllocationSlider>
    );
};