import { ethers } from "ethers";
import { createClient } from "urql";
import { abi } from "../utilities/abi";

export const getTokenStats = async (): Promise<{
  marketCap: number;
  viciPriceUsd: number;
  liquidityUsd: number;
}> => {
  const provider = new ethers.providers.JsonRpcProvider({
    url: process.env.REACT_APP_PROVIDER_URL
      ? process.env.REACT_APP_PROVIDER_URL
      : "",
  });
  const viciContract = new ethers.Contract(
    "0x6e01a56f0fd8e08b84297235c5847dcc469c96c9",
    abi,
    provider
  );
  const uniswapApiUrl =
    "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2";

  const client = createClient({ url: uniswapApiUrl });

  const viciPriceQuery = `
    query {
        pair (id : "0xdc48d779e8ad0cb2595ac53ddad9c9b032bc016f") {
        token1Price
        reserve1
        }
    }
    `;

  const ethUsdPriceQuery = `
    query {
        bundle(id: 1){
        id
        ethPrice
        }
    }
    `;

  let tokenStats = {
    marketCap: 0,
    viciPriceUsd: 0,
    liquidityUsd: 0,
  };

  await Promise.all([
    viciContract.totalSupply(),
    client.query(viciPriceQuery).toPromise(),
    client.query(ethUsdPriceQuery).toPromise(),
  ]).then(([totalSupplyBigNumber, viciPairData, ethUsdPrice]) => {
    const totalSupplyDecimal =
      ethers.BigNumber.from(totalSupplyBigNumber).toString();
    const totalSupply = totalSupplyDecimal.slice(
      0,
      totalSupplyDecimal.length - 9
    );
    const liquidityUsd =
      viciPairData.data.pair.reserve1 * ethUsdPrice.data.bundle.ethPrice * 2;
    const liquidityUsdRound = Math.round(liquidityUsd);
    const viciPriceUsd =
      viciPairData.data.pair.token1Price * ethUsdPrice.data.bundle.ethPrice;
    const viciPriceUsdRounded = viciPriceUsd.toFixed(6);
    const marketCap = Number(totalSupply) * viciPriceUsd;
    const marketCapRounded = Math.round(marketCap);
    tokenStats = {
      marketCap: marketCapRounded,
      viciPriceUsd: Number(viciPriceUsdRounded),
      liquidityUsd: liquidityUsdRound,
    };
  });
  return tokenStats;
};
