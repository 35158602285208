import styled from "styled-components";
import { lg, md, sm } from "../../utilities";

export const StyledWhatWeDo = styled.div`
    margin-top: 40px;
    padding: 0 40px 0 40px;

    @media ${lg} {
        padding: 0;
    }
`;

export const StyledWhatWeDoHeading = styled.h1`
    font-size: 36px;
    font-weight: 700;
    color: white;
    text-align: center;
    font-family: 'Bebas Neue';

    @media ${md} {
        font-size: 48px;
    }
`;

export const StyledWhatWeDoDescription = styled.p`
    font-size: 14px;
    color: white;
    opacity: 0.5;
    max-width: 500px;
    text-align: center;
    margin: 20px auto 20px auto;
`;

export const StyledFeatureGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;

    @media ${sm} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${md} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const StyledFeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: max-content;
`;

export const StyledFeatureImgWrappar = styled.div`
    height: 250px;
    display: flex;
`;

export const StyledFeatureImg = styled.img`
    padding: 0 10% 0 10%;
    height: 100%;
    width: 100%;
    margin: auto;
    max-width: 300px;

    @media ${sm} {
        max-width: auto;
    }
`;

export const StyledFeatureHeading = styled.h2`
    font-size: 28px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Bebas Neue';

    @media ${md} {
        font-size: 30px;
    }
`;

export const StyledFeatureDescription = styled.p`
    color: white;
    text-align: center;
    opacity: 0.5;
    font-size: 14px;
    margin-top: 20px;
`;