import React, { useState } from "react";
import { ImgProvider } from "./imgProvider";
import { RoadMapSlider } from "./RoadMapSlider";
const RoadMap: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  type SLIDES_DATA = Array<{ id: number; heading: String; text: String}>;
  const slides: SLIDES_DATA = [
    {
      id: 0,
      heading: "01. Token launch",
      text: "Cryptor (VICI) will be issued and created via the ERC20 Ethereum standard, with a total supply of 420 million tokens. "
    },
    {
      id: 1,
      heading: "02. Cryptor Ecosystem (BETA) rollout",
      text: "The first version of the Cryptor Ecosystem will be introduced. Etheruem will be the first supported network, with the Binance Smart Chain, Avalanche, Polygon to follow."
    },
    {
      id: 2,
      heading: "03. Full integration of the Cryptor Dashboard",
      text: "Cryptor will launch one of the most powerful dashboards in the industry, providing a clear, aggregated overview of news, tokens, NFTs, important transactions, charts, and features."
    },
    {
      id: 3,
      heading: "04. Staking contracts launch",
      text: "Cryptor Staking will be introduced, where users will have the opportunity to stake their tokens and get world-class APY along with limited NFTs as rewards."
    },
    {
      id: 4,
      heading: "05. Decentralised Exchange & Trading Bot",
      text: "The Cryptor dexchange will be integrated with a powerful Trading Bot, combined with a completely revamped UI & UX to further enhance user efficiency."
    },
  ];

  const handleSlideNumber = (id: number): void => {
    setActiveSlide(id);
  };

  return (
    <div
      className="w-full text-white text-center pt-6 sm:mt-28 space-y-5 my-10"
      id="roadmap"
    >
      <p className="text-sm font-light"></p>
      <h1 className="text-4xl md:text-5xl font-bold font-heading">ROADMAP</h1>

      <div>
        <div className="w-full p-6">
          <ImgProvider id={activeSlide} />
        </div>
        <RoadMapSlider
          handleSlideNumber={handleSlideNumber}
          activeSlide={activeSlide}
          slides={slides}
        />
      </div>
    </div>
  );
};

export default RoadMap;
