import styled from "styled-components";

export const StyledFaq = styled.div`
    background-color: #03161C;
`;

export const StyledHeader = styled.div`
    display: flex;
    text-transform: uppercase;
`;

export const StyledFaqHeading = styled.h1`
    color: white;
    font-size: 30px;
`;

export const StyledCloseButton = styled.button`
    margin-left: auto;
`;

export const StyledCloseImg = styled.img`
`;

export const StyledQuestion = styled.h3`
    margin-top: 15px;
    font-size: 15px;
    color: white;
`;

export const StyledAnswer = styled.p`
    margin-top: 8px;
    font-size: 13px;
    color: white;
    opacity: 0.4;
`;