import ReactPlayer from "react-player";
import styled from "styled-components";
import { md, sm } from "../../utilities";

export const StyledIntroSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 10px 0 10px;

    @media ${md} {
        padding: 50px 0 0 0;
    }
`;

export const StyledQuoteText = styled.h2`
    font-weight: 700;
    font-size: 29px;
    color: white;
    text-align: center;
    font-family: 'Bebas Neue';

    @media ${sm} {
        font-size: 35px;
        text-align: left;
    }
`;

export const StyledIntroQuoteHeading = styled.h1`
    color: #1CD465;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    line-height: 50px;
    font-family: 'Bebas Neue';

    @media ${sm} {
        font-size: 100px;
        text-align: left;
    }
`;

export const StyledWithCryptorLabel = styled.h2`
    color: white;
    font-size: 38px;
    text-align: center;
    font-weight: 700;
    font-family: 'Bebas Neue';

    @media ${sm} {
        font-size: 70px;
        text-align: left;
    }
`;

export const StyledViciSpan = styled.span`
    color: #1CD465;
`;

export const StyledIntroText = styled.p`
    font-size: 14px;
    color: white;
    font-weight: 300;
    text-align: center;
    margin-top: 10px;
    opacity: 0.5;

    @media ${sm} {
        text-align: left;
        max-width: 500px;
    }
`;

export const StyledEmbeded = styled(ReactPlayer)`
    margin: 60px auto 0 auto;
`;