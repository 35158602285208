import styled from "styled-components";

export const StyledHeader = styled.header`
    width: 100%;
    height: 100px;
    display: flex;
`;

export const StyledHeaderContent = styled.div`
    display: flex;
    height: 100px;
    width: 100%;
    padding: 0px 10px 0 10px;

    @media (min-width: 1024px) {
        padding: 0;
    }
`;