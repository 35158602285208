import Slider from "react-slick";
import styled from "styled-components";
import { md } from "../../../utilities";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const StyledTokenAllocationSlider = styled(Slider)`

    @media ${md} {
        
    }
`;

export const StyledTokenAllocationSlideWrapper = styled.div`
    padding: 0 10px 0 10px;
`;

export const StyledTokenAllocationSlide = styled.div`
    display: flex;
    border-radius: 10px;
    background-color: #17262C;
    height: max-content;
    width: auto;
`;

export const StyledPartition = styled.div`
    background-color: #2DFF85;
    width: 5px;
    height: auto;
    border-radius: 10px 0 0 10px;
`;

export const StyledTokenAllocationSlideContent = styled.div`
    padding: 10px;
    width: 100%;
`;

export const StyledHeading = styled.h2<{color: string}>`
    font-size: 16px;
    color: ${({color}) => color};
`;

export const StyledDescription = styled.p<{active: boolean}>`
    margin-top: 10px;
    font-size: 14px;
    opacity: ${({active}) => (active ? 1 : 0.5)};
`;