import React, { useCallback, useEffect, useMemo, useState } from "react";
import WhitePaper from "../WhitePaper/WhitePaper";
import { TokenAllocationSlider } from "./TokenAllocationSlider";
import tokenSaleCoin from "../../assets/token-sale-coin.svg";
import {
  StyledBuyTokenButton,
  StyledFaqButton,
  StyledStatLabel,
  StyledStatValue,
  StyledTokenName,
  StyledTokenNameLabel,
  StyledTokenomics,
  StyledTokenSaleActions,
  StyledTokenSaleCoin,
  StyledTokenSaleDescription,
  StyledTokenSaleHeading,
  StyledTokenSaleTimerContent,
  StyledTokenSaleTimerWrapper,
  StyledTokenStat,
  StyledTokenStatsWrappar,
  StyledWaves,
} from "./styles";
import { TokenAllocationImage } from "./TokenAllocationImage";
import { Quote } from "../Quote";
import waves from "../../assets/waves.svg";
import { CountDownTimer } from "./CountDownTimer";
import { getTokenStats } from "../../services/tokenStats";

interface IProps {
  showFAQ: () => void;
}

const Tokenomics: React.FC<IProps> = ({ showFAQ }) => {
  const [index, setIndex] = useState<number>(0);
  const [tokenStats, setTokenStats] = useState({
    marketCap: 0,
    viciPriceUsd: 0,
    liquidityUsd: 0,
  });

  useEffect(() => {
    getTokenStats().then((result) => {
      setTokenStats(result);
    });
  }, []);

  const renderTokenStats = useMemo(
    () => (
      <StyledTokenStatsWrappar>
        <StyledTokenStat>
          <StyledStatValue>
            ${tokenStats.marketCap.toLocaleString()}
          </StyledStatValue>
          <StyledStatLabel>Market Cap</StyledStatLabel>
        </StyledTokenStat>
        <StyledTokenStat>
          <StyledStatValue>${tokenStats.viciPriceUsd}</StyledStatValue>
          <StyledStatLabel>Token Price</StyledStatLabel>
        </StyledTokenStat>
        <StyledTokenStat>
          <StyledStatValue>0</StyledStatValue>
          <StyledStatLabel>Holders</StyledStatLabel>
        </StyledTokenStat>
        <StyledTokenStat>
          <StyledStatValue>
            ${tokenStats.liquidityUsd.toLocaleString()}
          </StyledStatValue>
          <StyledStatLabel>Liquidity Generated</StyledStatLabel>
        </StyledTokenStat>
      </StyledTokenStatsWrappar>
    ),
    [tokenStats.liquidityUsd, tokenStats.marketCap, tokenStats.viciPriceUsd]
  );

  return (
    <StyledTokenomics id="tokenomics">
      <StyledWaves src={waves} />
      <StyledTokenNameLabel>Token Name</StyledTokenNameLabel>
      <StyledTokenName>CRYPTOR</StyledTokenName>
      <TokenAllocationImage index={index}></TokenAllocationImage>
      <TokenAllocationSlider current={index} changeIndex={setIndex} />
      <Quote />
      <WhitePaper />
      <StyledTokenSaleHeading>TOKEN SALE</StyledTokenSaleHeading>
      <StyledTokenSaleTimerWrapper>
        <StyledTokenSaleTimerContent>
          <CountDownTimer />
          <StyledTokenSaleDescription>
            Token is now available to buy, enjoy a lot of benefits and take off
            with us. For more informations please go trough our FAQ's
          </StyledTokenSaleDescription>
          <StyledTokenSaleActions>
            <StyledBuyTokenButton
              href="https://app.uniswap.org/#/swap?inputCurrency=0x6e01A56F0Fd8e08B84297235c5847dCC469C96C9"
              target="_blank"
              rel="noreferrer"
            >
              Buy it
            </StyledBuyTokenButton>
            <StyledFaqButton onClick={showFAQ}>FAQ</StyledFaqButton>
          </StyledTokenSaleActions>
        </StyledTokenSaleTimerContent>
        <StyledTokenSaleCoin src={tokenSaleCoin} />
      </StyledTokenSaleTimerWrapper>
      {renderTokenStats}
    </StyledTokenomics>
  );
};

export default Tokenomics;
