import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styled from "styled-components";
import { md } from "../../../utilities";

export const StyledCountDownTimerContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledCountdownCircleTimer = styled(CountdownCircleTimer)`
    width: auto;
    margin-left: 12px;
`;

export const StyledTimerContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCountDownTime = styled.div`
    margin: auto;
    font-size: 15px;
    font-weight: 700;

    @media ${md} {
        font-size: 25px;
    }
`;

export const StyledCountDownDimension = styled.div`
    margin: auto;
    opacity: 50%;
    font-size: 10px;

    @media ${md} {
        font-size: 13px;
    }
`;