import React from "react";
import oneStopShop from "../../assets/one-stop-shop.svg";
import rankingAlgorithm from "../../assets/ranking-algorithm.svg";
import reinventingNft from "../../assets/reinventing-nft.svg";
import smartAlgorithm from "../../assets/smart-algorithm.svg";
import walletSystem from "../../assets/wallet-system.svg";
import makeDifference from "../../assets/make-difference.svg";
import { StyledFeatureContainer, StyledFeatureDescription, StyledFeatureGrid, StyledFeatureHeading, StyledFeatureImg, StyledFeatureImgWrappar, StyledWhatWeDo, StyledWhatWeDoDescription, StyledWhatWeDoHeading } from "./styles";

const WhatWeDo: React.FC = () => {
  type Features = Array<{
    id: number;
    icon: any;
    heading: String;
    subHeading: String;
  }>;
  const features: Features = [
    {
      id: 1,
      icon: oneStopShop,
      heading: "The One-Stop Shop of Crypto",
      subHeading: 
        "All you need to know in one place. Cryptor is your go-to decentralized platform for information gathering, with an innovative DEX tool and an unparalleled database featuring records from virtually all blockchains.",
    },
    {
      id: 2,
      icon: rankingAlgorithm,
      heading: "Trailblazing Ranking Algorithm",
      subHeading:
        "Searching for the next big thing is worthless if there’s no profit to be scored. That’s why we’ve made our users’ gains one of our core values and key focus points. With Cryptor, you’re maximizing your chances of locating the perfect hit.",
    },
    {
      id: 3,
      icon: reinventingNft,
      heading: "Reinventing NFT Marketplaces",
      subHeading:
        "NFTs are the future, and Cryptor is here to reshape the way the world thinks about them. With our state-of-the-art NFT Marketplace, you can not only trade and collect the hottest pieces, but also secure NFT rewards for staking.",
    },
    {
      id: 4,
      icon: smartAlgorithm,
      heading: "Cryptor Smart Algorithm",
      subHeading:
        "Cryptor’s robust engine searches all online databases, then evaluates, and ranks every single token with the most potential. Metrics, such as market cap, performance, holders, and many more are considered during the process.",
    },
    {
      id: 5,
      icon: walletSystem,
      heading: "Universal Wallet System",
      subHeading:
        "Most of the major crypto wallets are fully supported and integrated, so you can gather information and take action at the same time.With our very own Decentralized Exchange (DEX), hunting for true gems becomes simple, convenient, and, above all, ultra-fast.",
    },
    {
      id: 6,
      icon: makeDifference,
      heading: "Here to Make a Difference",
      subHeading:
        "The world will change dramatically in the near future. Cryptor’s here to take the lead and ignite the revolution. With a dedicated team, renowned partners, and ambitious goals, we are ready to bring tomorrow to your fingertips.",
    },
  ];
  const featureCard = features.map((data) => {
    return (
      <StyledFeatureContainer key={data.id}>
        <StyledFeatureImgWrappar>
          <StyledFeatureImg src={data.icon} alt=""/> 
        </StyledFeatureImgWrappar>
        <StyledFeatureHeading> {data.heading}</StyledFeatureHeading>
        <StyledFeatureDescription>
          {data.subHeading}
        </StyledFeatureDescription>
      </StyledFeatureContainer>
    );
  });
  return (
    <StyledWhatWeDo id="whatwedo">
      <StyledWhatWeDoHeading>WHAT WE DO</StyledWhatWeDoHeading>
      <StyledWhatWeDoDescription>
        Cryptor is the ultimate decentralized platform for everyone looking for a
        comprehensive market overview and state-of-the-art trading tools to securely
        locate and profit from the next big thing, be it a cryptocurrency or NFT.
      </StyledWhatWeDoDescription>
      <StyledFeatureGrid>
        {featureCard}
      </StyledFeatureGrid>
    </StyledWhatWeDo>
  );
};

export default WhatWeDo;
