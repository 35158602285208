import React from "react";
import { StyledContact, StyledContactForm, StyledContactFormContent, StyledContactFormMessage, StyledEmailInput, StyledEmailLink, StyledFormNotWorkingWarning, StyledMessageInput, StyledNameInput, StyledPhoneInput, StyledSendMessageButton, StyledStayConnectedHeading, StyledSubscribeButton, StyledSubscribeEmailInput, StyledSubscribeSection1, StyledSubscribeSection2, StyledSubscribeText, StyledSubscribeWrappar } from "./styles";

const Contact: React.FC = () => {
  return (
    <StyledContact>
      {/* <StyledSubscribeWrappar>
        <StyledSubscribeSection1>
          <StyledStayConnectedHeading>STAY CONNECTED</StyledStayConnectedHeading>
          <StyledSubscribeText>
            Subscribe to our newsletter and never miss any update
          </StyledSubscribeText>
        </StyledSubscribeSection1>
        <StyledSubscribeSection2>
          <StyledSubscribeEmailInput
            type="email"
            placeholder="Enter your email"
          />
          <StyledSubscribeButton>
            Subscribe
          </StyledSubscribeButton>
        </StyledSubscribeSection2>
      </StyledSubscribeWrappar> */}
      <StyledContactFormMessage>
        We always love to hear you
      </StyledContactFormMessage>
      <StyledFormNotWorkingWarning>
        Unfortunately, the contact form is not working. Send your queries to <StyledEmailLink href="mailto: cryptor.dev@cryptor.dev">cryptor.dev@cryptor.dev</StyledEmailLink>
      </StyledFormNotWorkingWarning>
      <StyledContactForm>
        <StyledContactFormContent>
          <StyledNameInput
            type="text"
            placeholder="Name"
            disabled
          />
          <StyledEmailInput
            type="text"
            placeholder="Email"
            disabled
          />
          <StyledPhoneInput
            type="text"
            placeholder="Phone"
            disabled
          />
          <StyledMessageInput
            name=""
            id=""
            placeholder="Message"
            disabled
          ></StyledMessageInput>
          <StyledSendMessageButton disabled>
            Send Message
          </StyledSendMessageButton>
        </StyledContactFormContent>
      </StyledContactForm>
    </StyledContact>
  );
};

export default Contact;
