import styled from "styled-components";
import { lg, md } from "../../utilities";

export const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    padding: 0 0 60px 0;

    @media ${md} {
        flex-direction: row;
        padding: 0 20px 60px 20px;
    }

    @media ${lg} {
        flex-direction: row;
        padding: 0 0 60px 0;
    }
`;

export const StyledFooterCryptorLogo = styled.img`
    margin: 0 auto 0 auto;

    @media ${md} {
        margin: 0;
    }
`;

export const StyledAboutCompany = styled.div`
    @media ${md} {
        width: 40%;
    }
`;

export const StyledQuikLinks = styled.div`
    width: 100%;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column-reverse;
    
    @media ${md} {
        flex-direction: column;
        width: 60%;
        margin: auto;
    }

`;

export const StyledQuickNavHeading = styled.h3`
    display: none;

    @media ${md} {
        display: block;
        color: white;
        font-size: 14px;
        font-weight: 500;
    }
`;

export const StyledQuickNavSection1 = styled.div`
    display: flex;
    margin: 20px auto 0 auto;
    width: max-content;

    @media ${md} {
        width: 100%;
    }
`;

export const StyledQuickNavSection2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    row-gap: 12px;
    color: white;
    font-size: 14px;
    font-weight: 300;
    width: 200px;
    margin: 0 auto 0 auto;

    @media ${md} {
        margin: 30px 0 0 0;
        display: flex;
        width: 100%;
        column-gap: 12px;
    }
`;

export const StyledNavLinks = styled.a`
    cursor: pointer;

    @media ${md} {
        margin-left: auto;

        &:nth-child(1) {
            margin-left: 0;
        }
    }
`;

export const StyledFooterText = styled.p`
    color: white;
    opacity: 0.5;
    font-weight: 300;
    font-size: 14px;
    width: 70%;
    margin: 30px auto 30px auto;
    text-align: center;

    @media ${md} {
        width: auto;
        text-align: left;
        opacity: 1;
        padding-right: 100px;
        margin-top: 20px;
    }
`;

export const StyledFooterSocialLinks = styled.div`
    display: flex;
    column-gap: 20px;
    margin: 0 auto 0 auto;
    width: max-content;

    @media ${md} {
        margin: 40px 0 0 0;
    }
`;

export const StyledFooterCopyright = styled.p`
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 20px auto 0 auto;

    @media ${md} {
        margin-top: 40px;
    }
`;

export const StyledDivider = styled.span`
    width: 80%;
    height: 2px;
    background-color: #707070;
    opacity: 0.23;
    margin: 20px auto 0 auto;
`;

export const StyledDisclaimerLink = styled.button`
    color: #1CD465;
    font-size: 15px;

    @media ${md} {
        margin-left: 30px;
    }
`;

export const StyledFaqLink = styled.button`
    color: #1CD465;
    margin-left: 30px;
    font-size: 15px;
`;
