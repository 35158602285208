import React from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import logo from "../../assets/cryptor-logo.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
//import linkedin from "../../assets/linkedin.svg";
import mail from "../../assets/mail.svg";
import telegram from "../../assets/telegram.svg";
import { StyledAboutCompany, StyledDisclaimerLink, StyledDivider, StyledFaqLink, StyledFooter, StyledFooterCopyright, StyledFooterCryptorLogo, StyledFooterSocialLinks, StyledFooterText, StyledNavLinks, StyledQuickNavHeading, StyledQuickNavSection1, StyledQuickNavSection2, StyledQuikLinks } from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { md } from "../../utilities";
import litePaper from "../../assets/litepaper.pdf";
gsap.registerPlugin(ScrollToPlugin);

interface IProps {
  showDisclaimer: () => void;
  showFaq: () => void;
};

const Footer: React.FC<IProps> = ({showDisclaimer, showFaq}: IProps) => {
  const isDesktop = useMediaQuery(md);
  const menuLink = [
    { name: "Home", link: "#intro", class: ".link-1" },
    { name: "What we do", link: "#whatwedo", class: ".link-2" },
    { name: "Our Team", link: "#team", class: ".link-3" },
    { name: "Roadmap", link: "#roadmap", class: ".link-4" },
    { name: "Tokenomics", link: "#tokenomics", class: ".link-5" },
  ];

  return (
    <StyledFooter>
      <StyledAboutCompany>
        <StyledFooterCryptorLogo src={logo} alt="" />
        <StyledFooterText>Cryptor brings innovation and functionality
          together, creating the first-of-its-kind interactive
          crypto database with tools that disrupt the world
          of crypto as you know it.
        </StyledFooterText>
        <StyledFooterSocialLinks>
          <a href="https://www.instagram.com/cryptorprotocol/"><img src={instagram} alt="" /></a>
          <a href="https://twitter.com/Cryptortweet"><img src={twitter} alt="" /></a>
          {/* <img src={linkedin} alt="" /> */}
          <a href="mailto: cryptor.dev@cryptor.dev"><img src={mail} alt="" /></a>
          <a href="https://t.me/cryptortalk"><img src={telegram} alt="" /></a>
        </StyledFooterSocialLinks>
        {isDesktop && <StyledFooterCopyright>© 2022 Cryptor. All rights reserved.</StyledFooterCopyright>}
      </StyledAboutCompany>
      <StyledQuikLinks>
        <StyledQuickNavSection1>
          <StyledQuickNavHeading>Quick Navigation</StyledQuickNavHeading>
          <StyledDisclaimerLink onClick={showDisclaimer}>Disclaimer</StyledDisclaimerLink>
          <StyledFaqLink onClick={showFaq}>FAQ</StyledFaqLink>
        </StyledQuickNavSection1>
        <StyledQuickNavSection2>
          {
            menuLink.map((item, i) => (
              <StyledNavLinks key={i} 
                onClick={() => {
                  gsap.to(window, { duration: 1, scrollTo: item.link });
                }}
              >{item.name}</StyledNavLinks>
            ))
          }
          <StyledNavLinks
            href={litePaper}
            target="_blank"
            rel="noreferrer"
          >Lite paper</StyledNavLinks>
        </StyledQuickNavSection2>
      </StyledQuikLinks>
      {!isDesktop && 
        <>
          <StyledDivider></StyledDivider>
          <StyledFooterCopyright>© 2022 Cryptor. All rights reserved.</StyledFooterCopyright>
        </>
      }
    </StyledFooter>
  );
};

export default Footer;
