import React from 'react'
import { StyledCloseQuotationMark, StyledOpenQuotationMark, StyledQuote, StyledQuoteContent, StyledQuotePersonName, StyledQuotePersonPosition, StyledQuoteWrappar } from './style';
import closeQuote from '../../assets/close-quote.svg';
import openQuote from '../../assets/open-quote.svg';

export const Quote: React.FC = () => {
    return (
        <StyledQuoteWrappar>
            <StyledOpenQuotationMark src={openQuote} />
            <StyledQuoteContent>
                <StyledQuote>the crypto market is really crazy at the moment and many unprofessional people want to take advantage of. Likely every second launch a new coin & token and cryptor is made to clean up the mess</StyledQuote>
                <StyledQuotePersonPosition>CEO & Founder</StyledQuotePersonPosition>
                <StyledQuotePersonName>Patric J. L.</StyledQuotePersonName>
            </StyledQuoteContent>
            <StyledCloseQuotationMark src={closeQuote} />
        </StyledQuoteWrappar>
    );
};